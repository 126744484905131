
@font-face {
  font-family: MadeTommy;
  src: url("/public/ressources/MadeTommyMediumPersonalUse.otf");
}


.bg-green {

  background-color: #033018;
}


.thumbnail {
  animation: slideUp 0.5s ease-in-out 1;
  
}


.arrow:hover {
  opacity: 70%;
  cursor: pointer;
}

.navbar-icon {
  height: 35px;
  width: 35px;
}

.thumbnail:hover {
  transition: 0.3s;
  transform: scale(1.05,1.05);
}


.custom-link {

  color: white;
}

.custom-link:hover {

  cursor: pointer;
  opacity: 70%;
}


.fade-in-element {
  transition-delay: 0.5s;
  transition: 0.7s;
  opacity: 0;
}
.fade-in-element.is-visible {
  opacity: 1;
  transform: translateY(0px) translateZ(0);
}

.from-bottom {
  transform: translateY(70px)  translateZ(0);
}

.from-top {
  transform: translateY(-200px)  translateZ(0);
}




.bg-blue {
  background-color: #071f28;;
  /*background-color: #0a111b;*/
  
}

.bg-dark-blue {

  background-color: #06181f;
}



#logo {
  aspect-ratio: 4;
}

.blur {
  backdrop-filter: brightness(30%);
}



@keyframes left-to-right-slide {
  0%     {transform: translate(-100%, 0%); z-index: 2;}
	100%    {transform: translate(0%, 0%); z-index: 2;}
}

@keyframes right-to-left-slide {
  0%     {transform: translate(100%, 0%);}
	100%    {transform: translate(0%, 0%);}
}

#title {
  border-radius: 0% 30% 30% 0%;
  animation: left-to-right-slide;
  animation-duration: 4s;
  /*background: linear-gradient(#3eb8739f 70%, #3c524a 30% ); */
}



#first-view {
  /*background: -webkit-linear-gradient(360deg, #05946f,#008a76,#007f79,#007378,#116873);                       background: linear-gradient(360deg, #05946f,#008a76,#007f79,#007378,#116873);/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */          
  background-image: url("/public/groupe1/LocalSombre.jpg");
  background-size: cover;
  background-position: 25% 75%;
                                                                                                                                          
}

body {
  overflow-x: hidden;
  background-image: url("/public/ressources/back-2.png");
  font-family: MadeTommy, sans-serif;
  

}


.shadow-black {
  box-shadow: 30px 30px 30px #141414;
}



.bg-feuille {

  background-image: url("/public/groupe1/MurFeuille.jpg");
  background-size: cover;
  background-position: 25% 50%;
}


opacity-h-50:hover {
  opacity: 0.5;
}

