.gallery {
	display: grid;
	width: 220px;
  }
  .gallery > img {
	grid-area: 1/1;
	width: 17vw;
	aspect-ratio: 1.7;
	object-fit: cover;
	border: 10px solid #071f28;
	border-radius: 15px;
	animation: slide 16s infinite;
  }
  
.gallery > img:nth-child(1) { animation-delay: 0s; }
.gallery > img:nth-child(2) { animation-delay: 4s; } 
.gallery > img:nth-child(3) { animation-delay: 8s; }
.gallery > img:nth-child(4) { animation-delay: 12s; }
  
  @keyframes slide {
	0%     {transform: translate(0%, 0%); z-index: 2;}
	25%    {transform: translate(80%, -50%); z-index: 2;}
	25.01% {transform: translate(80%, -50%); z-index: 1;} 
	50%    {transform: translate(0%, -75%); z-index: 1;}
	75%    {transform: translate(-80%, -50%); z-index: 1;}
	75.01% {transform: translate(-80%, -50%); z-index: 2;} 
	100%   {transform: translate(0%, 0%); z-index: 2;}  
  }
  
